import React, { useState, useEffect } from 'react';
import './QuizStyles.css';

function QuizQuestion({ question, onAnswerChange, submitted, userAnswer }) {

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    const shuffledChoices = [...question.distractors, question.answer];
    shuffleArray(shuffledChoices);
    setChoices(shuffledChoices);
  }, [question]);

  const handleAnswerSelect = (answer) => {
    onAnswerChange(answer);
  };

  const getChoiceClassName = (choice) => {
    if (submitted) {
      if (choice === question.answer) {
        return 'correctAnswer';
      } else if (choice === userAnswer) {
        return 'incorrectAnswer';
      }
    } else if(choice === userAnswer) {
      return 'userChoice';
    }
    return 'radioInput';
  };

  return (
    <div className="QuizQuestion">
      <h3>{question.question}</h3>
      {choices.map((choice, index) => (
        <label key={index} className={getChoiceClassName(choice)}>
          <input
            type="radio"
            name={question.question}
            value={choice}
            onChange={() => handleAnswerSelect(choice)}
            disabled={submitted}
          />
          {choice}
        </label>
      ))}
    </div>
  );
}

export default QuizQuestion;