import React, { useState } from 'react';
import QuizQuestion from './QuizQuestion';
import './QuizStyles.css';

function Quiz({ questions }) {
  const [answers, setAnswers] = useState({});
  const [score, setScore] = useState(null);

  const handleAnswerChange = (questionIndex, answer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionIndex]: answer,
    }));
  };
  

  const handleSubmit = () => {
    let correct = 0;
    questions.forEach((question, index) => {
      if (question.answer === answers[index]) {
        correct++;
      }
    });
    setScore(correct);
  };

  return (
    <div className="Quiz">
      {questions.map((question, index) => (
        <QuizQuestion
          key={index}
          question={question}
          onAnswerChange={(answer) => handleAnswerChange(index, answer)}
          submitted={score !== null}
          userAnswer={answers[index]}
        />
      ))}
      <button
        onClick={handleSubmit}
        disabled={questions.length !== Object.keys(answers).length}
      >
        Submit
      </button>
      {score !== null && <p>Your score: {score}/{questions.length}</p>}
    </div>
  );
}

export default Quiz;