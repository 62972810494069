class QuestionService {
  getMultipleChoiceQuestions(text) {
    const cloudFunctionUrl = 'https://us-central1-pro-sylph-381601.cloudfunctions.net/mcq_questions_from_text';
    //const cloudFunctionUrl = 'http://localhost:8081';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ text: text }),
    };

    return fetch(cloudFunctionUrl, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error fetching questions');
        }
      });
  }

  getTextFromUrl(url) {
    const cloudFunctionUrl = 'https://us-central1-pro-sylph-381601.cloudfunctions.net/text_from_article_url2';
    //const cloudFunctionUrl = 'http://localhost:8080';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ url: url }),
    };

    return fetch(cloudFunctionUrl, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error fetching text');
        }
      })
      .then(data => data.text);
  }

  getTopicQuestions(text) {
    const cloudFunctionUrl = 'https://us-central1-pro-sylph-381601.cloudfunctions.net/mcq_questions_from_topic2';
    //const cloudFunctionUrl = 'http://localhost:8082';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ text: text }),
    };

    return fetch(cloudFunctionUrl, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error fetching questions');
        }
      });
  }
}

export default new QuestionService();
