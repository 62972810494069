import React, { useState } from 'react';
import QuestionService from './services/QuestionService';
import Quiz from './components/Quiz';
import LoadingModal from './components/LoadingModal';
import ErrorModal from './components/ErrorModal';
import './components/QuizStyles.css';

function App() {
  const [questions, setQuestions] = useState([]);
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [sourceType, setSourceType] = useState('urlText');
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleErrorModalClose = () => {
    setShowErrorModal(false);
  };

  const handleError = () => {
    setShowErrorModal(true);
    setLoading(false);
  };

  const fetchQuestions = async () => {
    if (text && sourceType !== 'randomTopic') {
      setLoading(true);
      getTextForMCQs(text)
        .then(fetchedText => {
          if (fetchedText !== null && fetchedText !== "") {
            return QuestionService.getMultipleChoiceQuestions(fetchedText);
          } else {
            throw new Error('Failed to fetch questions');
          }
        })
        .then(fetchedQuestions => {
          setQuestions(fetchedQuestions);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching questions:', error);
          handleError();
        });
    } else if(text) {
      setLoading(true);
      QuestionService.getTopicQuestions(text).then(fetchedQuestions => {
        setQuestions(fetchedQuestions);
        setLoading(false);
      }).catch(error => {
        console.error('Error fetching questions:', error);
        handleError();
      });
    }
  };

  const getTextForMCQs = (text) => {
    if(text && sourceType === 'urlText') {
      return QuestionService.getTextFromUrl(text);
    } else {
      return Promise.resolve(text);
    }
  };

  const handleUrlChange = (e) => {
    setText(e.target.value);
  };

  const handleSourceTypeChange = (e) => {
    setSourceType(e.target.value);
  };

  const getRadioClassName = (currentSourceType) => {
    return sourceType === currentSourceType ? 'sourceRadioInputSelected' : 'sourceRadioInputUnselected';
  };

  return (
    <div className="App">
      <h1>Crispy Quiz</h1>
      <h2>Create a quiz on any body of text in seconds</h2>
      <br></br>
      <br></br>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          fetchQuestions();
        }}
      >
        <div className="sourceTypeDiv">
          <label className={`sourceRadioInput ${getRadioClassName('sourceText')} leftButton`} htmlFor="sourceText">
            <input
              type="radio"
              id="sourceText"
              name="sourceType"
              value="sourceText"
              checked={sourceType === 'sourceText'}
              onChange={handleSourceTypeChange}
            />
          Plain Text</label>
          <label className={`sourceRadioInput ${getRadioClassName('urlText')} middleButton`} htmlFor="textFromArticle">
            <input
              type="radio"
              id="textFromArticle"
              name="sourceType"
              value="urlText"
              checked={sourceType === 'urlText'}
              onChange={handleSourceTypeChange}
            />
          Text From Url</label>
          <label className={`sourceRadioInput ${getRadioClassName('randomTopic')} rightButton`} htmlFor="randomTopic">
          <input
            type="radio"
            id="randomTopic"
            name="sourceType"
            value="randomTopic"
            checked={sourceType === 'randomTopic'}
            onChange={handleSourceTypeChange}
            />
            Topic</label>
        </div>
        <div className="inputDiv">
          <h3>{sourceType === 'sourceText' ? 'Enter the text for quiz questions:' : sourceType === 'urlText' ? 'Enter article URL:' : 'Fetch questions from a random topic:'}</h3>
          <h4>{sourceType === 'sourceText' ? 'Questions are created directly from the text entered.' : sourceType === 'urlText' ? 'Questions are created directly from the article.' : 'Give us a theme or topic and we\'ll take care of the rest.'}</h4>
          {sourceType === 'sourceText' ? (
          <textarea value={text} onChange={handleUrlChange} />
          ) : sourceType === 'urlText' ? (
          <input type="text" value={text} onChange={handleUrlChange} />
          ) : (
            <textarea value={text} onChange={handleUrlChange} />
          )}
          <button type="submit">Fetch Questions</button>
          </div>
          </form>
          {loading && <LoadingModal />}
          {showErrorModal && <ErrorModal onClose={handleErrorModalClose} />}
          {questions && questions.length > 0 && <Quiz questions={questions} />}
        </div>
        );
        }
        
        export default App;